import React, { useState } from 'react';
import { graphql } from 'relay-runtime';
import '../../../stylesheets/components/globals.module.scss';
import '../../../stylesheets/components/theme.module.scss';
import '../../../stylesheets/components/buttonGlobals.module.scss';
import '../../../stylesheets/components/dropdownMenus.module.scss';
import '../../../stylesheets/components/dialog.module.scss';
import Text from '../tenant_portal/components/Text';
import PortalButton from '../components/PortalButton';
import { useMutation } from 'react-relay';
import { ForgotPasswordLoginMutation$data } from './__generated__/ForgotPasswordLoginMutation.graphql';
import { ErrorType } from '../components/Types';
import PageHeader from '../tenant_portal/PageHeader';
import SubmitSpinner from '../components/SubmitSpinner';
import SuccessPage from '../tenant_portal/SuccessPage';
import { formatErrorMessages } from '../components/Tools';
import Alert from '../components/Alert';

export const LoginForgotPasswordMutation = graphql`
  mutation ForgotPasswordLoginMutation($input: LoginForgotPasswordInput!) {
    loginForgotPassword(input: $input) {
      errors {
        path
        messages
      }
    }
  }
`;

const ForgotPassword = () => {
  const [emailField, setEmailField] = useState('');
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [commitMutation, isMutationInFlight] = useMutation(
    LoginForgotPasswordMutation
  );

  const baseErrors = errors.filter(
    (error) => error.path.split('.').slice(-1)[0] === 'base'
  );
  const formattedBaseErrorMessages = formatErrorMessages(baseErrors, '');

  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  const handleSubmit = () => {
    if (emailField === '') {
      setErrors([
        {
          path: 'email',
          messages: ["can't be blank"],
        },
      ]);
      return;
    }

    commitMutation({
      variables: {
        input: {
          login: {
            email: emailField,
          },
        },
      },
      onCompleted: (response) => {
        const responseData = response as ForgotPasswordLoginMutation$data;
        console.log('completed! response: ', responseData);
        if (!responseData || !responseData.loginForgotPassword) {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          return;
        }
        if (
          responseData.loginForgotPassword.errors &&
          responseData.loginForgotPassword.errors.length > 0
        ) {
          setErrors(responseData.loginForgotPassword.errors as ErrorType[]);
          return;
        }
        setIsEmailSent(true);
      },
      onError: (error) => {
        setErrors([
          {
            path: 'base',
            messages: [
              'There was an error. Please contact Property Matrix support for assistance.',
            ],
          },
        ]);
        console.error(error);
      },
    });
  };

  if (isMutationInFlight)
    return <SubmitSpinner spinnerText={'Sending email...'} />;

  if (isEmailSent)
    return (
      <SuccessPage
        successText="Email sent"
        successSubText="Please check your email for a link to reset your password."
      />
    );

  return (
    <>
      {baseErrors.length > 0 && (
        <Alert severity="error">{formattedBaseErrorMessages}</Alert>
      )}
      <PageHeader title="Reset password" />
      <Text
        field={{
          label: 'Email',
          name: 'email',
          type: 'email',
          value: emailField,
          autocomplete: 'username',
        }}
        mode="edit"
        onChange={(fieldName, value) => setEmailField(value?.toString() || '')}
        onKeyDown={onEnterDown}
        errors={errors.filter(
          (error) => error.path.split('.').slice(-1)[0] === 'email'
        )}
      />
      <PortalButton onClick={handleSubmit}>Reset password</PortalButton>
    </>
  );
};

export default ForgotPassword;
